import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import { ConfigProvider } from 'antd'
import ruRu from 'antd/es/locale/ru_RU'
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Router } from 'react-router-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './i18n'
import ScrollToTop from './hoc/scroll-to-top'
import ErrorBoundary from './hoc/error-boundary'
import 'antd/dist/antd.less'
import { history } from './api'
import * as serviceWorker from './serviceWorkerRegistration'
import ServiceWorkerWrapper from './hoc/service-worker-wrapper'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

export const queryCache = new QueryCache({
  onError: error => {
    console.log(error)
  },
})
export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Router history={history}>
      <ServiceWorkerWrapper>
        <ScrollToTop>
          <ErrorBoundary>
            <ConfigProvider locale={ruRu}>
              <ReactQueryDevtools initialIsOpen={false} />
              <GoogleReCaptchaProvider reCaptchaKey={'6LftrXEqAAAAACpijMU2CyWCCS_itDIpKRND1pPJ'}>
                <App />
              </GoogleReCaptchaProvider>
            </ConfigProvider>
          </ErrorBoundary>
        </ScrollToTop>
      </ServiceWorkerWrapper>
    </Router>
  </QueryClientProvider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

serviceWorker.register()
