import React from 'react'
import { Tag } from 'antd'
import { priceFormatter } from '../../../../lib/priceFormatter'
import { useTranslation } from 'react-i18next'
import { DollarOutlined, PercentageOutlined } from '@ant-design/icons'

interface props {
  value: number | undefined
  sum?: boolean
}

const TeacherRate: React.FC<props> = ({ value, sum }) => {
  const { t } = useTranslation()

  const renderValue = () => {
    if (typeof value !== 'number') return 'Значение неизвестно'
    if (value > 0) {
      if (sum) return <span>{priceFormatter(value)}</span>
      return <span>{value}</span>
    }

    return <span>{t('absent')}</span>
  }

  return (
    <Tag
      icon={sum ? <DollarOutlined /> : <PercentageOutlined />}
      color={value && value > 0 ? 'blue' : 'volcano'}
    >
      {renderValue()}
    </Tag>
  )
}

export default React.memo(TeacherRate)
